import styled from 'styled-components'; 
import "./new.css";
const SectionWrapper = styled.section`
  min-height: 100vh;
  width: 100vw;
  position: relative;
  background-color: #000000;
  display: flex;
  margin: 0 auto;
  color: white;
  font-family: 'Albert Sans', sans-serif;

`;

const TextWrapper = styled.div`
  z-index: 10;
`;

const LeftContainer = styled.div`
  width: 100%;
  text-align:center;
  top: 40%;
  left:50%
  font-size:1.5em;
  position: absolute;
  text-transform:uppercase;
  z-index: 10;
`;

const Home = () => {
  return (
    <SectionWrapper id="fixed-target" className="about">
      

      <LeftContainer
        data-scroll
        data-scroll-sticky
        data-croll-direction="#fixed-target"
      >
      <p className="end-text"
        data-scroll data-scroll-speed="-2">
        A creative developer based in Munich. <br />
        My aim is to help people achieve a brighter audience <br />
        by making their digital wishes come true. <br />
        Also, I am learning to design J4F. <br />
      </p>
      </LeftContainer>
       
      <TextWrapper >
      <p className="header-sl-new" data-scroll data-scroll-speed="-2" onClick={() => window.open('mailto:leopoldweber123@gmail.com?subject=Work%20With%20Me')}>
        WORK WITH ME
      </p>
      </TextWrapper>
    </SectionWrapper>
  );
};

export default Home;
