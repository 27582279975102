import styled from 'styled-components';
import "./second.css";
import img01 from '../../assets/img/41_old.png';
import img02 from '../../assets/img/2.png';
import img03 from '../../assets/img/3.png';
import img04 from '../../assets/img/41.png';
// import img04 from '../../assets/img/41.png';
import img05 from '../../assets/img/bmw.png';
import img06 from '../../assets/img/fendt.png';
import img09 from '../../assets/img/omron.png';
const SectionWrapper = styled.section`
  min-height: 150vh;
  width: 100%;
  position: relative;
  background-color: #000000;
  display: flex;
  margin: 0 auto;
  color: white;
  font-family: 'Albert Sans', sans-serif;

  .prod-four {
    filter: grayscale(100%);
    transition: filter 0.3s ease-in-out;
  }
  
  .prod-four:hover {
    filter: grayscale(0%);
  }
`;

const TextWrapper = styled.div`
  z-index: 10;
  @media (max-width: 768px) {
    display: none;
  }
`;

const LeftContainer = styled.div`
  z-index: 10;
  overflow: hidden;
  width:1000px;
   
`;



const Home = () => {
  return (
    <SectionWrapper id="fixed-target" className="about">
      <LeftContainer
        data-scroll
   
      >
        
      {/* <a href='https://weber-angela.de/' target="_blank" rel="noopener noreferrer" className="prod-text-one"
        data-scroll  
        data-scroll-delay=".12"
        data-scroll-speed="-2">
        CHANCELLERY
      </a>
      <img className="prod-one" src={img01} alt=""  
          data-scroll  
          data-scroll-delay=".12"
          data-scroll-speed="-3" 
          onClick={() => window.open('https://weber-angela.de/', '_blank')} /> */}


      <a href='https://www.barleben-komm.de/' target="_blank" rel="noopener noreferrer" className="prod-text-one"
        data-scroll  
        data-scroll-delay=".12"
        data-scroll-speed="-2">
        CHANCELLERY
      </a>
      <img className="prod-one" src={img01} alt=""  
          data-scroll  
          data-scroll-delay=".12"
          data-scroll-speed="-3" 
          onClick={() => window.open('https://www.barleben-komm.de/', '_blank')} />


      <a href='https://shopnav.great-site.net/?i=1' className="prod-text-two"
        data-scroll  
        data-scroll-delay=".12"
        data-scroll-speed="-2">
        Person Tag
      </a>
      <img className="prod-two" src={img02} alt=""  
          data-scroll  
          data-scroll-delay=".12"
          data-scroll-speed="-3" 
          onClick={() => window.open('https://shopnav.great-site.net/?i=1', '_blank')} />


      <a href='https://shopnav.great-site.net/?i=1' className="prod-text-three"
        data-scroll  
        data-scroll-delay=".12"
        data-scroll-speed="-2">
        ShoppingGPS
      </a>
      <img className="prod-three" src={img03} alt=""  
          data-scroll  
          data-scroll-delay=".12"
          data-scroll-speed="-3" 
          onClick={() => window.open('https://shopnav.great-site.net/?i=1', '_blank')} />


      <a href='https://almazfriseurmuc.rf.gd/' className="prod-text-four"
        data-scroll  
        data-scroll-delay=".12"
        data-scroll-speed="-2">
        ALMAZ
      </a>
      <img className="prod-four" src={img04} alt=""  
          data-scroll  
          data-scroll-delay=".12"
          data-scroll-speed="-3" 
          onClick={() => window.open('https://almazfriseurmuc.rf.gd/', '_blank')} />


      <a href='https://myworkplace.bmw.com/iwp/' className="prod-text-five"
        data-scroll  
        data-scroll-delay=".10"
        data-scroll-speed="-1">
        BMW.MyW
      </a>
      <img className="prod-five" src={img05} alt=""  
          data-scroll  
          data-scroll-delay=".10"
          data-scroll-speed="-2" 
          onClick={() => window.open('https://myworkplace.bmw.com/iwp/', '_blank')} />


      <a href='https://eisen-fendt.shop/' className="prod-text-six"
        data-scroll  
        data-scroll-delay=".12"
        data-scroll-speed="-1">
        Fendt
      </a>
      <img className="prod-six" src={img06} alt=""  
          data-scroll  
          data-scroll-delay=".12"
          data-scroll-speed="-3" 
          onClick={() => window.open('https://eisen-fendt.shop/', '_blank')} />


      <a href='https://industrial.omron.de/de/home' className="prod-text-seven"
        data-scroll  
        data-scroll-delay=".12"
        data-scroll-speed="-2">
        OMRON
      </a>
      <img className="prod-seven" src={img09} alt=""  
          data-scroll  
          data-scroll-delay=".12"
          data-scroll-speed="-3" 
          onClick={() => window.open('https://industrial.omron.de/de/home', '_blank')} />
      </LeftContainer>
       
      <TextWrapper >
      <span className="acros-left" data-scroll>
      CREATING COOL STUFF WHILE<br />
      LEARNING NEW SUPERPOWERS<br />
      </span>
      </TextWrapper>
    </SectionWrapper>
    
  );
};

export default Home;
