import React from 'react';
import ImageDepthMap from 'react-depth-map';
import image from '../assets/depth_img/_WNT0885-5_sd.png';
import depthmap from '../assets/depth_img/3_1_sd.png';

function DepthMap() {
  const threshold = 22;

  return (
    <div style={{ 
      position: 'absolute', 
      top: 0, 
      left: 0, 
      width: "100%", 
      height: "100%",
      overflow: 'hidden'
    }}>
      <ImageDepthMap
        originalImg={image}
        depthImg={depthmap}
        verticalThreshold={threshold}
        horizontalThreshold={threshold}
      />
    </div>
  );
}

export default DepthMap;