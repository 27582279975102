import React, { useState } from 'react'; // Make sure to import useState
import styled, { keyframes } from 'styled-components';
import Video from '../assets/video.mp4';
import { motion } from 'framer-motion';
import "./cover.css";
import { useEffect } from 'react';
import DepthMap from './depth.jsx'; // Import the PixiComponent

const flashAnimation = keyframes`
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Flash = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  animation: ${flashAnimation} 2.5s ease-out forwards;
  z-index: 13;
`;


const SectionWrapper = styled.section`
  min-height: 100vh;
  width: 100%;
  position: relative;
  background-color: black; // Hintergrundfarbe hinzugefügt
  
  video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
`;

const OverLay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 1;
  background-color: ${(props) =>
    `rgba(${props.theme.bodyRgba}, 0.7)`};
`;

const TitleWrapper = styled(motion.div)`
  position: absolute;
  top: 47%;
  left: 1%;
  z-index: 8;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    display: flex;
  }
  
`;

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      delayChildren: 5, // 2
      staggerChildren: 0.3,
    },
  },
};

const itemVariants = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
  },
};



const CoverVideo = () => {
  const [videoEnded, setVideoEnded] = useState(false);
  const [videoStarted, setVideoStarted] = useState(false);
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    if (videoEnded) {
      setTimeout(() => setOpacity(1), 0); // Start the transition immediately after video ends
    }
  }, [videoEnded]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVideoStarted(true);
    }, 3255); // 3255 seconds delay

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);

  return (
    <SectionWrapper>
      {!videoEnded && <OverLay />}
      <TitleWrapper
        initial="hidden"
        animate="show"
        variants={containerVariants}
      >
        <div>
          <motion.h1
            variants={itemVariants}
            data-scroll
            data-scroll-delay=".12"
            data-scroll-speed="2"
          >
            <p className="cover-head-vid">Leopold Weber</p>
          </motion.h1>
        </div>
      </TitleWrapper>
    {videoStarted && !videoEnded && <video src={Video} type="video/mp4" autoPlay muted onEnded={() => setVideoEnded(true)} />}
    {videoEnded && <Flash />}
    {videoEnded && <DepthMap/>}
      
      {/* {videoEnded && <video src={depthvid} style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100vh', objectFit: 'cover', opacity: opacity, transition: 'opacity 2s ease-in-out'}} autoPlay loop muted />} */}


    </SectionWrapper>
  );
};

export default CoverVideo;
