import styled from 'styled-components';
import "./banner.css";
import ban01 from '../../assets/img/pod01.png';
import ban02 from '../../assets/img/pod02_d.png';
import ban03 from '../../assets/img/Heckaton2024.png';
import ban04 from '../../assets/img/LeoRaumfahrt.png';
import ban05 from '../../assets/img/barleben.png';
import "./about.css";
import { keyframes } from 'styled-components';
import React, { useEffect, useRef } from 'react';

const SectionWrapper = styled.section`
  min-height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:black;
  z-index: 12;
`;

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const BannerComponent = styled.div`
  font-size: ${(props) => props.theme.fontxxxl};
  font-family: 'Lora', serif;
  color: ${(props) => props.theme.text};
  white-space: nowrap;
  text-transform: lowercase; // force text to be in lowercase
  line-height: 1;
  position: relative;
  font-weight: 200; // make the text thinner
  &:hover span {
    display: block;
  }
  span {
    display: none;
    position: absolute;
    top: 50%;
    left: ${(props) => props.position === 'right' ? '-20%' : '80%'};
    transform: translateY(-50%);
    background-color: transparent;
    padding: 1rem 2rem;
    font-size: 30px;
    font-family: Arial; // change the font to Arial
    white-space: normal; // allow text to wrap
    width: 80%; // limit width to prevent overflow
    animation: ${fadeIn} 1s ease-in; // apply the fade-in animation
  }
`;

const Banner = () => {
  const imgRef = useRef(null);

  useEffect(() => {
    if (imgRef.current) { // check if imgRef is not null
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          const { isIntersecting } = entry;
          if (isIntersecting) {
            imgRef.current.src = imgRef.current.dataset.src;
            observer.disconnect();
          }
        });
      });

      observer.observe(imgRef.current);
    }
  }, [imgRef]); // add imgRef to the dependency array
  return (
    <SectionWrapper>
      <Container id="up">
        <BannerComponent position="right"
          data-scroll
          data-scroll-direction="horizontal"
          data-scroll-speed="8"
          data-scroll-target="#up"
        >
          <img src={ban01} className="ban-pic" draggable="false" />
          {/* <span style={{maxWidth: '500px', wordWrap: 'break-word', marginRight: '50px', textAlign: 'center', fontFamily: "'Albert Sans', sans-serif"}}>While Working for BMW i had the Opportunity to visit Porto</span> */}
        </BannerComponent>
        <BannerComponent position="left"
          data-scroll
          data-scroll-direction="horizontal"
          data-scroll-speed="-6"
          data-scroll-target="#up"
        >
          <img src={ban02} className="ban-pic" draggable="false"/>
          {/* <span style={{fontFamily: "'Albert Sans', sans-serif"}}>Text for image 1</span> */}
        </BannerComponent>
        
        <BannerComponent position="left"
          data-scroll
          data-scroll-direction="horizontal"
          data-scroll-speed="6"
          data-scroll-target="#up"
        >
          <img src={ban03} className="ban-pic" draggable="false"/>
        </BannerComponent>

        <BannerComponent position="right"
          data-scroll
          data-scroll-direction="horizontal"
          data-scroll-speed="-4"
          data-scroll-target="#up"
        >
          <img src={ban04} className="ban-pic" draggable="false"/>
          {/* <span className="acros-left" style={{fontFamily: "'Albert Sans', sans-serif"}}>Text for image 1</span> */}
        </BannerComponent>

        <BannerComponent position="left"
          data-scroll
          data-scroll-direction="horizontal"
          data-scroll-speed="2"
          data-scroll-target="#up"
        >
          <img src={ban05} className="ban-pic" draggable="false"/>
        </BannerComponent>


      </Container>
    </SectionWrapper>
  );
};

export default Banner;